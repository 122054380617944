<template>
    <div>
        <div class="fof-wrapper text-center">
            <span class="fof-text primary-text font-weight-bold">404</span>
            <p style="margin-top: -5rem">
                Sorry, but the page you're looking for can't be found.
                <br>
                Click <router-link to="/">here</router-link> to go back to your homepage.
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FOF"
    }
</script>

<style scoped>
.fof-wrapper{

    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%)
}

.fof-text{
    font-size: 20rem;
    opacity: 50%;
}
</style>